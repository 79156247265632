<template>
   <SuppliersPage
    banner_location="giftcards_home-top"
    supplier_router="EndUserGiftCardSupplier"
    product_router="EndUserGiftCardProduct"
    page_title="gift_cards"
  ></SuppliersPage>
</template>

<script>
  import SuppliersPage from '@/components/endUser/suppliersPages/SuppliersPage.vue';

  export default {
    name: 'EndUserGiftCard',
    components: {
      SuppliersPage,
    },
  }
</script>
